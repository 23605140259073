/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */

export enum AnchorType {
    DEFAULT = 'soul-link',
    PLAIN = 'soul-link soul-link--plain',
    GHOST = 'soul-link soul-link--ghost',
}

export enum ButtonType {
    DEFAULT = 'soul-button soul-button--default',
    PRIMARY = 'soul-button soul-button--primary',
    NEGATIVE = 'soul-button soul-button--negative',
    GHOST = 'soul-button soul-button--ghost',
    PLAIN = 'soul-button soul-button--plain',
}
