/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
export enum GridBreakpoint {
    XL = '-on-xl',
    L = '-on-l',
    M = '-on-m',
    S = '-on-s',
    XS = '-on-xs',
}
