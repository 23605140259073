import * as React from 'react';
import {PropsWithChildren, ReactNode} from 'react';
import {Icons} from "../icon/IconsSet";
import {AnchorType, ButtonType} from "./LinkTypes";
import {SignDensity} from "../sign/SignModifiers";
import Sign, {SignProps} from "../sign/Sign";
import {Size} from "../Sizes";
import {Link} from "gatsby";
import {predicates} from "../../../utils/Predicates";

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
interface LinkProps {
    to: string,
    linkType?: AnchorType | ButtonType,
    active?: boolean,
    className?: string,
    icon?: Icons,
    iconSize?: Size,
    iconAfter?: boolean,
    attributes?: any,
}

const SLink: React.FC<PropsWithChildren<LinkProps>> = (props) => {
    let classes: string = props.linkType || '';
    let children: ReactNode = props.children;

    if (props.active) {
        classes += ' active'
    }

    if (props.className) {
        classes += ` ${props.className}`;
    }

    if (!!props.icon) {
        const signProps: SignProps = {
            icon: props.icon,
            density: SignDensity.REGULAR,
            size: props.iconSize,
            iconAfter: props.iconAfter || false
        };

        children = <Sign {...signProps}>{children}</Sign>;
    }

    if (props.attributes || !predicates.isInternalURL(props.to)) {
        return <a href={props.to} className={classes} {...props.attributes}>{children}</a>
    }

    return <Link to={props.to} className={classes}>{children}</Link>
}

export default SLink;
