import {menuType} from "../components/utils/footerMenu";

const GATSBY_EXCLUDED = process.env.GATSBY_EXCLUDED;

/**
* @author ESTECO CSA Team <csa@esteco.com>
*/

export const subsitesPath = {
    all: [/\/india\/.*/, /\/north-america\/.*/],
    india: [/\/india\/agenda.*/],
    na: [/\/north-america\/.*/],
    nas: [/\/north-america\/speakers.*/]
}

export const predicates = {
    isInternalURL: (url: string) => url.startsWith('/'),
    isNotEmpty: (arr: any) => !!arr && Array.isArray(arr) && arr.length > 0,
    isEmpty: (arr: any) => !predicates.isNotEmpty(arr)
}

export const filterMenu = (menu :menuType[]) => {
    const filteredLinks = menu.filter(link => link.enabled);
    let excludedPaths: RegExp[] = (GATSBY_EXCLUDED || '').split(':')
        .reduce<RegExp[]>((exclP, excl) => {
            if (!!excl) {
                exclP.push(...subsitesPath[excl as (keyof typeof subsitesPath)]);
            }
            return exclP;
        }, []);
    return filteredLinks
        .filter(link => !excludedPaths.some(p => !link.url.startsWith('https') && p.test(link.url)));
}
