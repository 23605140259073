import * as React from 'react';
import {PropsWithChildren} from 'react';
import {GridDensity} from './GridDensity';
import {GHAlignment, GVAlignment} from './GridAlignment';
import {GridDirection} from "./GridDirection";

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
interface GridProps {
    direction: GridDirection,
    density?: GridDensity,
    vAlignment?: GVAlignment,
    hAlignment?: GHAlignment,
    className?: string,
    wrap?: boolean
}

const Grid: React.FC<PropsWithChildren<GridProps>> = (props) => {
    let properties: GridProps | string | string [] = {...props};
    properties.vAlignment = props.vAlignment || GVAlignment.START;
    properties.hAlignment = props.hAlignment || GHAlignment.START;
    properties = Object.values(properties).filter(prop => typeof prop === 'string' && !!prop);

    if (!!props.wrap) {
        properties.push('wrap');
    }

    if (properties.length > 0) {
        properties = ` soul-grid--${properties.join(' soul-grid--')}`;
    } else {
        properties = '';
    }

    return <div className={`soul-grid${properties} esteco-custom-grid-gap`}>
        {props.children}
    </div>
}

export default Grid;
