import * as React from 'react';
import {calculateResponsiveSpan, calculateSpan, GIColSpanType} from './GridItemSpan';
import {GridBreakpoint} from "./GridBreakpoint";
import {PropsWithChildren} from "react";

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
interface GridItemProps {
    colSpan?: GIColSpanType,
    responsive?: {
        size: GIColSpanType,
        breakpoint: GridBreakpoint | GridBreakpoint[]
    }[]
}

const GridItem: React.FC<PropsWithChildren<GridItemProps>> = (props) => {
    const colSpan = props.colSpan || 0;
    const properties: string[] = [calculateSpan(colSpan)];

    if (!!props.responsive) {
        for (const resp of props.responsive) {
            properties.push(...calculateResponsiveSpan(resp.size, resp.breakpoint));
        }
    }

    return <div className={`soul-grid-item-${properties.join(' soul-grid-item-')}`}>
        {props.children}
    </div>;
}

export default GridItem;
