import * as React from 'react';
import {Icons} from "./IconsSet";
import {Size} from "../Sizes";

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
interface LinkProps {
    icon: Icons,
    size?: Size,
    additionalClasses?: string,
    inline?: boolean
}

const Icon: React.FC<LinkProps> = (props) => {
    const classes = ['a-icon', `a-icon--${props.icon}`];
    classes.push(props.size ? `a-icon--${props.size}` : `a-icon--${Size.S_S}`);

    if(props.additionalClasses) {
        classes.push(props.additionalClasses);
    }

    if(props.inline) {
        classes.push('a-icon--inline');
    }

    return <span className={classes.join(' ')}></span>;
}


export default Icon;
