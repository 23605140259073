/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */

export enum Size {
    S_2XS= '2-xs',
    S_XS= 'xs',
    S_S= 's',
    S_M= 'm',
    S_L= 'l',
    S_XL= 'xl',
    S_2XL= '2-xl',
    S_3XL= '3-xl',
}
