import {GridBreakpoint} from "./GridBreakpoint";

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
enum GISpan {
    AUTO = 'auto-span',
    FILL = 'fill-span',
    I1_2 = '1/2-span',
    I1_3 = '1/3-span',
    I1_4 = '1/4-span',
    I1_6 = '1/6-span',
    I1_12 = '1/12-span',
    I5_12 = '5/12-span',
    I7_12 = '7/12-span',
    I8_12 = '8/12-span',
    I9_12 = '9/12-span',
    I10_12 = '10/12-span',
    I11_12 = '11/12-span',
    I12_12 = '12/12-span',
}

const colSpans = [
    GISpan.AUTO,    // O - Auto
    GISpan.I1_12,   // 1 Col
    GISpan.I1_6,    // 2 Cols
    GISpan.I1_4,    // 3 Cols
    GISpan.I1_3,    // 4 Cols
    GISpan.I5_12,   // 5 Cols
    GISpan.I1_2,    // 6 Cols
    GISpan.I7_12,   // 7 Cols
    GISpan.I8_12,   // 8 Cols
    GISpan.I9_12,   // 9 Cols
    GISpan.I10_12,  // 10 Cols
    GISpan.I11_12,  // 11 Cols
    GISpan.I12_12,  // 12 Cols

]

export type GIColSpanType = 'fill' | 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 'hidden';

export const fullSpanOnMobile:{size: GIColSpanType, breakpoint: GridBreakpoint | GridBreakpoint[]} =
    {
        size: 12,
        breakpoint: [GridBreakpoint.S, GridBreakpoint.XS]
    };

export const hideSpanOnMobile:{size: GIColSpanType, breakpoint: GridBreakpoint | GridBreakpoint[]} =
    {
        size: 'hidden',
        breakpoint: [GridBreakpoint.S, GridBreakpoint.XS]
    };

export const hideSpanOnDesktop:{size: GIColSpanType, breakpoint: GridBreakpoint | GridBreakpoint[]} =
    {
        size: 'hidden',
        breakpoint: [GridBreakpoint.XL, GridBreakpoint.L, GridBreakpoint.M]
    };

export function calculateResponsiveSpan(colSpan: GIColSpanType, bkp: GridBreakpoint | GridBreakpoint[]) {
    let responsiveStrings = [];
    let span = 'hidden';
    let breakpoint = bkp;
    if (colSpan !== 'hidden') span = calculateSpan(colSpan);


    if (!Array.isArray(breakpoint)) {
        breakpoint = [breakpoint];
    }

    for (const bpt of breakpoint) {
        responsiveStrings.push(span + bpt)
    }

    return responsiveStrings;
}

export function calculateSpan(colSpan: GIColSpanType) {
    switch (colSpan) {
        case 'hidden':
            return `-${colSpan}`;

        case 'fill':
            return GISpan.FILL;

        default:
            return colSpans[colSpan];
    }
}
