import * as React from 'react';
import Icon from "../icon/Icon";
import {Icons} from "../icon/IconsSet";
import {SignDensity, SignType} from "./SignModifiers";
import {Size} from "../Sizes";
import {PropsWithChildren} from "react";

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
export interface SignProps {
    icon: Icons,
    size?: Size
    iconAfter?: boolean,
    type?: SignType,
    density: SignDensity,

}

const Sign: React.FC<PropsWithChildren<SignProps>> = (props) => {
    let classes: string[] = [props.density];

    if (props.type) {
        classes.push(props.type);
    }

    if (props.iconAfter) {
        classes.push(SignType.REVERSE);
    }

    return <div className={`soul-sign${classes.length > 0 ? ` ${classes.join(' ')}` : ''}`}>
            <span className="soul-sign__icon">
                <Icon icon={props.icon} inline={true} {...props.size}/>
            </span>
        <span className="soul-sign__text">{props.children}</span></div>;
}

export default Sign;
