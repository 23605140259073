/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
export enum GHAlignment {
    START = 'justify-start',
    CENTER = 'justify-center',
    END = 'justify-end',
    SPACE_AROUND = 'justify-space-around',
    SPACE_BETWEEN = 'justify-space-between',
    EVENLY = 'justify-evenly'
}

export enum GVAlignment {
    START = 'align-start',
    CENTER = 'align-center',
    END = 'align-end',
    STRETCH = 'align-stretch'
}
