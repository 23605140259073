/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */

export enum SignType {
    INLINE = 'soul-sign--inline',
    REVERSE = 'soul-sign--rev',
}

export enum SignDensity {
    REGULAR = 'soul-sign--regular',
    COMPACT = 'soul-sign--compact',
    COMFORTABLE = 'soul-sign--comfortable',
}
