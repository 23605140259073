import * as React from "react";
import {PropsWithChildren} from "react";

let svgUniqueID = 0;
/**
 * @author ESTECO CS Team <csa@esteco.com>
 */
interface ImageProps {
    className?: string,
    color: string,
}

const Image: React.FC<PropsWithChildren<ImageProps>> = ({children, color, className}) => {
    const style = { "--svgcolor": color } as React.CSSProperties;
    return (<div className={`esteco-svg${className ? ` ${className}` : ''}`} style={style}>
                {children}
            </div>);
};

export default Image;
